import * as React from "react";
import Header from "../components/Header";
import Seo from "../components/Seo";
import Footer from "../components/Footer";
import Banner from "../components/Banner";

interface BigHeadProps {
  children: React.ReactNode;
  title: string;
  description: string;
  intro?: string;
  hideTitle?: boolean;
}

const BigHead = ({ children, title, description, intro, hideTitle }: BigHeadProps) => {
  const displayTitle = hideTitle ? "" : title;
  return (
    <>
      <Seo title={title} description={description} />
      <Header />
      <div className="flex flex-col min-h-screen">
        <main className="grow flex flex-col">
          <Banner title={displayTitle} intro={intro}>
            {children}
          </Banner>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default BigHead;
