import * as React from "react";
import { LoginBanner } from "../components/ActionButtons";
import BigHead from "../layouts/BigHead";

const LoginPage = () => {
  const title = "Log In";
  const description = "Log into your music glue account";

  return (
    <BigHead title={title} description={description} hideTitle>
      <section className="relative grow max-w-xl mx-auto">
        <div className="flex flex-col gap-4 py-4 mx-auto">
          <div className="bg-mg-green text-mg-purple border border-mg-green md:mb-0 mb-8 p-4 no-underline grow hover:shadow-xl overflow-clip rounded-md flex flex-col">
            <div className="basis-1/2 py-4 min-h-16">
              <h2 className="text-xl mb-4 mx-4">Customer Login</h2>
              <p className="text-sm mb-2 md:mb-1 font-normal">
                If you have bought something and have a query about your order
              </p>
              <a href="/customer-help" className="btn bg-mg-purple text-white mt-4 hover:bg-white hover:text-mg-purple">Log in</a>
            </div>
          </div>
          <div className="bg-transparent text-white border border-white md:mb-0 mb-8 py-4 no-underline grow hover:shadow-xl overflow-clip rounded-md flex flex-col flex-1">
              <div className="basis-1/2 py-4 min-h-24">
                <h2 className="text-xl mb-4 mx-4">Music Glue (Subscription)</h2>
                <p className="text-sm mb-2 md:mb-1 font-normal">
                  If you have a Lite, Standard or Advanced account.
                </p>
                <a href="https://admin.musicglue.store/log-in" className="btn bg-mg-green text-white mt-4 hover:bg-white hover:text-mg-purple" target="_blank">Log in</a>
              </div>
          </div>
          <div className="bg-transparent text-white border border-white md:mb-0 mb-8 py-4 no-underline grow hover:shadow-xl overflow-clip rounded-md flex flex-col flex-1">
              <div className="basis-1/2 py-4 min-h-24">
                <h2 className="text-xl mb-4 mx-4">Music Glue (Legacy)</h2>
                <p className="text-sm mb-2 md:mb-1 font-normal">
                  If you use Music Glue to sell merch, music, tickets or fan clubs.
                </p>
                <a href="https://user.musicglue.com/log-in" className="btn bg-mg-green text-white mt-4 hover:bg-white hover:text-mg-purple" target="_blank">Log in</a>
              </div>
          </div>
        </div>
      </section>
    </BigHead>
  );
};

export default LoginPage;
